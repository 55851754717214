import { FC } from 'react'
import './input.scss'
import { useTranslation } from 'react-i18next'

type inputProps = {
    type: string
    value: string
    placeholder: string
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    disabled?: boolean
    error?: string
    id?: string
}


const Input:FC<inputProps> = ({type, value, placeholder, onChange, disabled, error, id}) => {
    const [ t, i18n ] = useTranslation('errors')


    return (
        <div className="input-wrapper">
            <input className={error? 'input-error input': 'input'}
                id={id}
                type={type}
                value={value}
                onChange={onChange}
                disabled={disabled}
                placeholder={placeholder}
            />
            {
                error?
                    <div className="input-error-text">{t(`${error}`)}</div>
                    : null
            }
        </div>
    )
}

export default Input