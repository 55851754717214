import './paypal.scss'

import CurrencyInput from 'react-currency-input-field';

import { useRef, useEffect, FC, useState } from "react";
import { loadScript, PayPalNamespace, PayPalButtonsComponentOptions } from "@paypal/paypal-js";

import { useTranslation } from 'react-i18next'

const Paypal: FC = () => {
  const [ t, i18n ] = useTranslation('donate')

  const paypalRef = useRef<HTMLDivElement | null>(null);

  const [donationAmount, setDonationAmount] = useState(1)

  useEffect(() => {
    // Load PayPal SDK with Venmo support 
    loadScript({
      clientId: "AcmmVreLbkwREtshI_YalJ8r-rCJfg0_7QzT3lcWZXMlG5If-UNgpwR0de3E1fuZOPrMdEGw8zbQM-YX", // Replace with your actual client ID
      currency: "USD", // Venmo supports only USD
      components: "buttons", // Load the buttons component
      intent: "capture", // Set intent globally to "capture"
    }).then((paypal: PayPalNamespace | null) => {
      if (!paypal || !paypal.Buttons) {
        console.error("PayPal Buttons failed to load.");
        return;
      }

        if (paypalRef.current) {
          paypalRef.current.innerHTML = "";
        }

      const buttonOptions: PayPalButtonsComponentOptions = {
        style: {
          shape: "rect",
          color: "gold",
          layout: "vertical",
          label: "donate", // Generic label for multiple payment methods
        },
        createOrder: (data, actions) => {
          if (!actions?.order) {
            console.error("Order actions are unavailable.");
            return Promise.reject(new Error("Order actions are unavailable."));
          }
          return actions.order.create({
            intent: "CAPTURE", // Ensure intent is included
            purchase_units: [
              {
                description: "Donation",
                amount: {
                  currency_code: "USD", // Venmo supports only USD
                  value: `${donationAmount}`, // Placeholder amount
                },
              },
            ],
          });
        },
        onApprove: async (data, actions) => {
          if (!actions?.order) {
            console.error("Order actions are unavailable.");
            return;
          }
          const order = await actions.order.capture();
          console.log("Payment successful:", order);
        },
        onError: (err) => {
          console.error("PayPal Checkout onError:", err);
        },
      };

      // Render both PayPal and Venmo buttons (conditionally)
      paypal.Buttons({
        ...buttonOptions,
        fundingSource: paypal.FUNDING?.PAYPAL, // PayPal button
      }).render(paypalRef.current as HTMLDivElement);

      paypal.Buttons({
        ...buttonOptions,
        fundingSource: paypal.FUNDING?.VENMO, // Venmo button
      }).render(paypalRef.current as HTMLDivElement);
    }).catch((error) => {
      console.error("Failed to load PayPal script:", error);
    });
  }, [donationAmount]);

  return (
    <div className="paypal-wrapper">
      <label htmlFor="donation-amount">{t('donationAmount')}</label>
      <CurrencyInput
        id='donation-amount'
        defaultValue={`${donationAmount}`}
        placeholder="0.0"
        decimalsLimit={2}
        onValueChange={(value, name, values) => {setDonationAmount(values?.float || 0)}}
        prefix="$"
      />
      <div className="paypal-container" ref={paypalRef}></div>
    </div>
  )
};

export default Paypal;
